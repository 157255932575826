import Modal from "../../../../components/modal";
import {H1Modal} from "../../../../components/heads";
import React from "react";

import Form from "../confirm-email-form";



function RegistrationStep2({open}) {
  return (
      <>
        <Modal show={open === 21} w={434}>
          <H1Modal>
            Подтверждение e-mail
          </H1Modal>
          <Form confirm={'registration'}/>
        </Modal>
      </>
  )
}

export default RegistrationStep2

