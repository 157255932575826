import React from "react";
import * as yup from "yup";
import LoginContext from "../context";
import {useFormik} from "formik";
import {InptLabel} from "../../../components/inpt";
import {Button, ButtonSecondary} from "../../../components/btn";
import styled from "styled-components";
import Timer from "./timer";
import {instance, setToken} from "../../../api/http-util";
import config from "../../../api/config";

const Div1 = styled.div`
  height: 10px;
  @media (max-height: 568px) {
    height: 4px;
  }
`
const Div2 = styled.div`
  height: 27px;
  @media (max-height: 568px) {
    height: 20px;
  }
`
const DivBtns = styled.div`
  display: flex;
  justify-content: flex-end;

`
const DivBtn = styled.div`
  width: 131px;
  margin-left: 12px;
`
const DivCounter = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  padding-top: 10px;
`

const validationSchema = yup.object({
  code: yup.string().min(5, 'Введите корректный код').required('Введите код')
})

export default function Form({confirm}) {
  const {window, setWindow} = React.useContext(LoginContext)
  const [isFocus, setIsFocus] = React.useState(false)
  const [isTimer, setIsTimer] = React.useState(false)
  const [error, setError] = React.useState('')
  React.useEffect(() => {
    setIsTimer(window === 21 || window === 11)
  }, [window])
  const onSubmit = () => {
    let endpoint = ''
    if (confirm === 'password') {
      endpoint = config.endpoints.password_recovery_code
    }
    if (confirm === 'registration') {
      endpoint = config.endpoints.registration_code
    }
    const data = new FormData()
    data.append('code', formik.values.code)
    instance(endpoint, data)
        .then(r => {
          const {status, headers} = r
          if (status === 200) {
            setToken(headers['x-token'])
            setWindow(3)
            formik.handleReset(undefined)
          }
        })
        .catch(e => {
          const {data} = e.response
          setError(data.detail)
        })
  }

  const newCode = () => {
    let endpoint = ''
    if (confirm === 'password') {
      endpoint = config.endpoints.password_recovery_code_renew
    }
    if (confirm === 'registration') {
      endpoint = config.endpoints.registration_code_renew
    }
    instance(endpoint)
        .then(() => {
          setIsTimer(true)
        }).catch((e) => {
      const {data} = e.response
      setError(data.detail)
    })
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  })
  React.useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isFocus) {
          formik.handleSubmit()
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik, isFocus]);
  return (
      <form onSubmit={formik.handleSubmit}>
        <DivCounter>
          На ваш e-mail было направлено письмо c временным кодом <br/> <br/>
          <Timer
              show={isTimer}
              onClick={() => newCode()}
              seconds={5}
              text={'Получить новый код через  '}
              callbackTimer={stateTimer => setIsTimer(stateTimer)}
          />
        </DivCounter>
        <Div2/>
        <InptLabel
            placeholder="Введите код из e-mail"
            type={'tel'}
            name={'code'}
            onChange={e => {
              formik.handleChange(e)
              formik.setSubmitting(false)
              return setError('')
            }}
            onBlur={e => {
              formik.handleBlur(e)
              return setIsFocus(false)
            }}
            error={
              formik.touched.code && formik.errors.code
                  ? formik.errors.code
                  : error
            }
            label={'Временный код'}
            value={formik.values.code}
            colorError={'red'}
            onFocus={() => setIsFocus(true)}

        />
        <Div1/>
        <DivBtns>
          <DivBtn>
            <ButtonSecondary
                onClick={() => {
                  setWindow(0)
                  setIsTimer(false)
                  setError('')
                  return formik.handleReset(undefined)
                }}
                type={'button'}
            >
              Отмена
            </ButtonSecondary>
          </DivBtn>
          <DivBtn>
            <Button
                type={'submit'}
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            >
              Отправить
            </Button>
          </DivBtn>
        </DivBtns>
      </form>
  )
}