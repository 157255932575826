import React from "react";
import styled from "styled-components";
import {IconDownArrow} from "../../../components/icons";
import {useNavigate} from "react-router-dom";




// line - соединительная линия в от элемента в диаграмме
// to - переход по роуту
// status - подключен (Down), доступен для подключения(Process), не доступент для подклюяения(Tobe)
export function FuncCard(props) {
  const navigate = useNavigate()
  const handleClick = () => {
    if (props.to){
      navigate(props.to)
    }
  }
  let typeCard = ''
  switch (props.status) {
    case 'Down':
      typeCard = 'Down'
      break;
    case 'Process':
      typeCard = 'Process'
      break;
    default: typeCard = 'Tobe'
  }
  return (
      <DivCard onClick={handleClick}>
        <Card className={typeCard}>
          <div className={'icon'}>
            <IconDownArrow/>
          </div>
          {props.children}
          <Line position={props.line}/>
          <Tooltip className={'tooltip'}>тултип</Tooltip>
        </Card>
      </DivCard>
  )
}

function Card(props){
  return(
      <StyledCard {...props}>
        {props.children}
      </StyledCard>
  )
}
const Tooltip = styled.div`
  position: absolute;
  display: none !important;
  //background-color: rgba(255,255,255,0.9);
  background-color: rgba(0,0,0,0.5);
  color: #F1F3F4;
  padding: 5px;
  border-radius: 5px;
  border:1px solid #850101;
  width: 100px;
  min-height: 18px;
  top:-40px;
  right: -50px;
  align-items: center;
  justify-content: center;
  z-index: 999;
  &::after{
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

`
const StyledCard = styled.div`
  position: relative;
  display: inline-flex;
  padding: 5px 12px;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0,0,0,0.125);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  &:hover ${Tooltip}{
      display: flex !important;
  } 
`
const Line = styled.div`
    position: absolute;
    width: ${props => props.position === 'right'
    ? "20px"
    : props.position === 'left'
        ? "20px"
        :0 };
    height: ${props => props.position === 'top'
    ? "20px"
    : props.position === 'bottom'
        ? "20px"
        :0 };
    border-bottom: ${props => props.position === 'right'
    ? "1px"
    : props.position === 'left'
        ? "1px"
        : 0 } solid rgba(0,0,0,0.125);
    border-left:${props => props.position === 'top'
    ? "1px"
    : props.position === 'bottom'
        ? "1px"
        : 0 } solid rgba(0,0,0,0.125);
    left:${props => props.position === 'left' ? "-21px" : "auto"};
    right: ${props => props.position === 'right' ? "-21px" : "auto"};
    top:${props => props.position === 'top' ? "-21px" : "auto"};
    bottom: ${props => props.position === 'bottom' ? "-21px" : "auto"};
  `
const DivCard = styled.div`
  display: flex;
  width: 150px;
  height: 41px;
  position: relative;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  
  
  & div {
    //position: relative;
    flex-direction: column;
    text-align: center;
    line-height: 108%;
    font-size: 12px;
  }

  & .Down {
    position: relative;
    flex-direction: column;
    border: 1px solid #850101;
    background-color: #DCDFE3;
    text-align: center;
    font-weight: 500;
    box-sizing: border-box;

    & .icon {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    &:hover{
      box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
    }
    &:active{
      box-shadow: none;
      cursor: pointer;
    }
  }

  & .Process {
    position: relative;
    flex-direction: column;
    //border: 1px solid rgba(0, 0, 0, 0.125);
    border: 1px solid #CED0D1;
    background-color: #FFF;
    box-sizing: border-box;
    & .icon {
      display: none;
    }
    &:hover{
      box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
    }
    &:active{
      box-shadow: none;
      cursor: pointer;
    }
    
  }

  & .Tobe {
    position: relative;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #EFF1F2;
    text-align: center;
    box-sizing: border-box;
    & .icon {
      display: none;
    }
  }
`
