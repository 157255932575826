import React from 'react'
import styled from "styled-components"
import colors from "../config/style.json"
import {useNavigate} from "react-router-dom";

export const ClickSpan = styled.span`
  color: ${props => props.red ? colors.colors.main:colors.colors.font};
  outline: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
`

export function ClickSpanN(props){
  const navigate = useNavigate()
  const onClick = (to) => {
    if (to){
      return navigate(to)
    } else {
      return null
    }

  }
  return (
      <ClickSpan onClick={()=>onClick(props.to)} {...props}>
        {props.children}
      </ClickSpan>
  )
}