import React from "react";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../components/modal";
import {H1Modal} from "../../../components/heads";
import {useFormik} from "formik";
import LoginContext from "../context";
import {InptLabel} from "../../../components/inpt";
import {Button, ButtonSecondary} from "../../../components/btn";
import {instance} from "../../../api/http-util";
import config from "../../../api/config";
import {useNavigate} from "react-router-dom";



function NewPassword({open}) {
  return (
      <>
        <Modal show={open===3} w={434}>
          <H1Modal>
            Новый пароль
          </H1Modal>
          <Form/>
        </Modal>
      </>
  )
}
export default NewPassword

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/
const validationSchema = yup.object({
  password1: yup
      .string().min(6, 'Пароль должен быть не менее 6 символов')
      .matches(PASSWORD_REGEX, "Слишком простой пароль")
      .required("Пожалуйста, введите пароль"),
  password2: yup
      .string()
      .required("Пожалуйста, повторите пароль")
      .when("password", {
        is: (val) => (val && val.length > 0),
        then: yup
            .string()
            .oneOf([yup.ref("password")], "Пароли не совпадают"),
      }),
})

function Form(){
  const {setWindow} = React.useContext(LoginContext)
  const [isFocus, setIsFocus] = React.useState(false)
  const [error, setError] = React.useState('')
  const navigate = useNavigate()
  const onSubmit = () => {
    const data = new FormData()
    data.append('password1', formik.values.password1);
    data.append('password2', formik.values.password2);
    instance(config.endpoints.password_update, data)
        .then(r => {
          const {status} = r
          if (status === 200){
            formik.handleReset(undefined)
            navigate('/')
          }
        })
        .catch(e => {
          const {data} = e.response
          setError(data.detail)
        })



  }
  const formik = useFormik({
    initialValues: {
      password1: '',
      password2: ''
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  })
  React.useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isFocus){
          formik.handleSubmit()
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik, isFocus]);
  return (
      <form onSubmit={formik.handleSubmit}>
        <DivFlex>
          <InptLabel
              placeholder="Введите новый пароль"
              label='Пароль'
              colorError={'red'}
              value={formik.values.password1}
              type={'password'}
              name={'password1'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password1 && formik.errors.password1
                  ? formik.errors.password1
                  : ''
              }
              autoComplete='on'
              // onFocus={handleFocus}

          />
          <Div1/>
          <InptLabel
              placeholder="Введите пароль еще раз"
              label='Проверка пароля'
              colorError={'red'}
              value={formik.values.password2}
              type={'password'}
              name={'password2'}
              onChange={e => {
                formik.handleChange(e)
                formik.setSubmitting(false)
                return setError('')
              }}
              onBlur={e=> {
                formik.handleBlur(e)
                return setIsFocus(false)
              }}
              error={formik.touched.password2 && formik.errors.password2
                  ? formik.errors.password2
                  : error
              }
              autoComplete='on'
              onFocus={()=>setIsFocus(true)}
          />
        </DivFlex>

        <Div1/>
        <DivBtns>
          <DivBtn>
            <ButtonSecondary
                type={'button'}
                onClick={() => {
                  setWindow(0)
                  return formik.handleReset(undefined)
                }}
            >
              Отмена
            </ButtonSecondary>
          </DivBtn>
          <DivBtn>
            <Button
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                type={'submit'}>
              Продолжить
            </Button>
          </DivBtn>
        </DivBtns>
      </form>
  )
}

const Div1 = styled.div`
  height: 10px;
  @media (max-height: 450px) {
    height: 4px;
    width: 16px;
  }
`

const DivBtns = styled.div`
  display: flex;
  justify-content: flex-end;

`
const DivBtn = styled.div`
  width: 131px;
  margin-left: 12px;
`
const DivFlex = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-height: 450px) {
    flex-direction: row;

  }
`