import React, {useContext} from "react";
import {ClickSpanN} from "../../components/anchor";
import MainContext from "../main/context";

function Dashboard(){
  const {settings} = useContext(MainContext)

  return(
      <>
        <ClickSpanN red to={settings.openPage}> Вернуться к странице </ClickSpanN>
        <br/>
      </>
  )
}
export default Dashboard