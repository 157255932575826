import React from "react";
import styled from "styled-components";



export function CheckBox(props){
  return(
      <StyledChk>
        <label>
          {props.label}
            <input type="checkbox" {...props}/>
          <span/>
        </label>
      </StyledChk>
  )
}

const StyledChk = styled.div`
    //padding-top: 7px;
    & label{
      display: block;
      position: relative;
      padding-left: 24px;
      //margin: 0;
      //margin-bottom: 7px;
      padding-top: 2px;
      cursor: pointer;
      font-size: inherit;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      & input{
        //position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked{
          &~span{
            background-color: #850101;
            border-radius: 2px;
            &:after {
              display: block;
            }
          }
        }
      }
      & span {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 2px;
        border: 1px solid #DCDFE3;
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 5px;
          top: 2px;
          width: 3px;
          height: 7px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      &:hover{
        & input{
          &~span{
            border: 1px solid #B4B8BE !important;
          }
        }
      }
    }
`