import React from "react";
import styled from "styled-components";
import {IconCompany} from "../../../components/icons";

export const CompanyCards = (props) => {
  return (
          <>
            <StyledCard>
              <div>
                <IconCompany/>
              </div>
              <div className={'company'}>
                <div className={'name--company'}>
                  Наименование организации
                </div>
                <div className={'inn--company'}>ИНН: 778686575</div>
              </div>
              <Parameters>
                <div>Параметр</div>
                <div className={'points'}>................................................................................</div>
                <div style={{whiteSpace: 'nowrap'}}>6</div>
                <div>Параметр</div>
                <div className={'points'}>.................................................................................</div>
                <div style={{whiteSpace: 'nowrap'}}>6</div>
                <div>Параметр</div>
                <div className={'points'}>..................................................................................</div>
                <div style={{whiteSpace: 'nowrap'}}>6</div>
              </Parameters>
            </StyledCard>
          </>
      )
}

const StyledCard = styled.div`
  display: grid;
  grid-template-columns: 52px 1fr;
  grid-row-gap: 8px;
  padding: 15px;
  background-color: #fff;
  
  border-radius: 7px;
  border: 1px solid rgba(0,0,0,0.125);
  & svg{
    margin-left: -5px;
    margin-top: -5px;
  }
  & .company{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 16px;
    
    padding-bottom: 5px;
  }
  & .name--company{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #595E69;
    display: flex;
    align-items: center;
    padding-bottom: 4px;
  }
  & .inn--company{
    font-size: 12px;
  }
`

const Parameters = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 3px;
  grid-row-gap: 8px;
  color: #8A8F98;
  font-size: 12px;
  font-weight: 400;
  & .points{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: fade;
  }
`
