import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import logo from '../../static/cmlogo.svg'
import {IconExit, IconFiles, IconNotif, IconsArrow} from "../../components/icons";
import {ClickSpan} from "../../components/anchor";
import LeftPanel from "./left-panel";
import {useNavigate} from "react-router-dom";
import MainContext from "./context";



function TopPanel() {
  const menuRef = React.useRef(null)
  const panelRef = React.useRef(null)
  const navigate = useNavigate()
  const [menuUserOpen, setMenuUserOpen] = useState(false)
  const {profile, user} = React.useContext(MainContext)

  function onClickOutMenuUser(e) {
    if (!menuRef.current.contains(e.target) && !panelRef.current.contains((e.target))) {
      setMenuUserOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutMenuUser, true);
    return () => {
      document.removeEventListener('click', onClickOutMenuUser, true);
    }
  })
  return (
      <>
        <NavS>
          <Logo onClick={()=>navigate('/board')}>
          </Logo>
          <div style={{display: 'flex'}}>
            <UserPanel onClick={() => setMenuUserOpen(!menuUserOpen)} ref={panelRef}>
              <span style={{paddingRight: 8, paddingTop: 1}}>
                {profile.f_name} {profile.l_name} {profile.p_name}
              </span>
              <IconsArrow/>
              <UserMenu
                  ref={menuRef}
                  show={menuUserOpen}
              >
                <UserItem>
                  <IconUser>
                    {profile.l_name && profile.l_name.substring(0, 1).toUpperCase() }
                  </IconUser>
                  <UserInfo>
                    <div>
                      {profile.f_name} {profile.l_name} {profile.p_name}
                    </div>
                    <div className={'emailUser'}>
                      {user.email}
                    </div>
                  </UserInfo>
                </UserItem>
                <Item onClick={()=>navigate('/user-profile')}>
                  <ClickSpan>
                    Мой профиль
                  </ClickSpan>
                </Item>
                <Item onClick={()=>navigate('/company')}>
                  <ClickSpan>
                    Мои компании
                  </ClickSpan>
                </Item>
              </UserMenu>
            </UserPanel>
            <div style={{display: 'flex', padding: '0 12px'}}>
              <UserPanelSmall onClick={() => setMenuUserOpen(!menuUserOpen)} >
                <IconUser hidden={menuUserOpen}>
                  {profile.l_name && profile.l_name.substring(0, 1).toUpperCase() }
                </IconUser>
                <UserMenu ref={menuRef} show={menuUserOpen}>
                  <UserItem>
                    <IconUser>
                      {profile.l_name && profile.l_name.substring(0, 1).toUpperCase() }
                    </IconUser>
                    <UserInfo>
                      <div>
                        {profile.f_name} {profile.l_name} {profile.p_name}
                      </div>
                      <div className={'emailUser'}>
                        {user.email}
                      </div>
                    </UserInfo>
                  </UserItem>
                  <Item onClick={()=>navigate('/user-profile')}>
                    <ClickSpan >
                      Мой профиль
                    </ClickSpan>
                  </Item>
                  <Item onClick={()=>navigate('/company')}>
                    <ClickSpan>
                      Мои компании
                    </ClickSpan>
                  </Item>
                </UserMenu>
              </UserPanelSmall>
              <NavTopIcon> <IconFiles/></NavTopIcon>
              <NavTopIcon> <IconNotif/> </NavTopIcon>
              <NavTopIcon onClick={() => navigate('/login')}> <IconExit/> </NavTopIcon>
            </div>
          </div>
        </NavS>
        <LeftPanel/>
      </>
  )
}

export default TopPanel

const NavS = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
  //min-width: 1024px;
  height: 50px;
  z-index: 998;

`
const Logo = styled.div`
  display: flex;
  align-self: center;
  margin-left: 6px;
  transition: 1s;
  width: 40px;
  height: 40px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;

  &:hover {
    transform: rotate(180deg);
  }
`
const UserPanel = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
  z-index: 999;
  background-color: #fff;
  position: relative;
  border-radius: 7px;
  @media (max-width: 668px) {
    display: none;
  }
`
const UserPanelSmall = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
  z-index: 999;
  background-color: #fff;
  position: relative;
  border-radius: 7px;
  margin-right: 5px;
  padding-left: 48px;
  @media (min-width: 668px) {
    display: none;
  }
`

const UserMenu = styled.div`
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  position: absolute;
  min-width: 100%;
  top: -14px;
  right: -12px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
  padding: 12px 0;
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  transform: ${props => props.show ? 'scale(1)' : 'scale(0.9)'};;
  opacity: ${props => props.show ? 1 : 0};
  @media (max-width: 668px) {
    position: fixed;
    top: 52px;
    right: 0;
    border-radius: 0;
    padding-top: 30px;
    padding-bottom: 57px;
  }
  @media (min-width: 440px) {
    min-width: 0;
  }
`
const Item = styled.div`
  position: relative;
  cursor: pointer;
  padding: 8px 28px;

  & span {
    font-size: 14px !important;
    white-space: nowrap;
  }

  &:hover {
    background-color: #F1F3F4;
  }
  @media (max-width: 668px) {
    padding-left: 60px;
  }
`
const UserItem = styled.div`

  display: flex;
  padding: 0 28px;
  margin-bottom: 10px;
  & span {
    font-size: 13px !important;
    white-space: nowrap;
  }
  @media (max-width: 668px) {
    padding-left: 60px;
    margin-bottom: 29px;
  }

`
const IconUser = styled.div`
  display: ${props => props.hidden ? 'none' : 'flex'};
  justify-content: center;
  margin-right: 7px;
  align-items: center;
  font-size: 20px;
  background-color: #820000;
  color: #f1f3f4;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 32px;
  height: 32px;
  border-radius: 50%;
`
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding-top: 1px;
  & div {
    white-space: nowrap;
    
  }
  & .emailUser{
    font-size: 11px !important;
  }
  
`
const NavTopIcon = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  justify-content: space-between;
  opacity: 0.8;
  transition: all 0.1s;
  cursor: pointer;
  padding: 0 12px;

  &:hover {
    opacity: 1;
    transform: translateY(-1px);
  }
  

`