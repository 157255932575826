import React from 'react'
import styled from "styled-components"
import colors from "../config/style.json"
// import {phone} from "./utils/phone";


export const Inpt = styled.input`
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none !important;
  border: 1px solid ${colors.colors.btnSecondHover};
  font-size: 14px;
  height: 38px !important;
  border-radius: 7px !important;
  padding: 6px 32px 6px 12px;
  color: ${colors.colors.font} !important;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.8;
    transition: opacity 0.3s;
    font-size: 13px;
    font-weight: 300;
    white-space: nowrap !important;
  }

  &::-moz-placeholder {
    color: inherit;
    opacity: 0.8;
    transition: opacity 0.3s;
    font-size: 13px;
    font-weight: 300;
  }

  &:-moz-placeholder {
    color: inherit;
    opacity: 0.8;
    transition: opacity 0.3s;
    font-size: 13px;
    font-weight: 300;
  }

  &:-ms-input-placeholder {
    color: inherit;
    opacity: 0.8;
    transition: opacity 0.3s;
    font-size: 13px;
    font-weight: 300;
  }
  &:disabled{
    background-color: #E9ECEF;
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  &:focus::-moz-placeholder {
    opacity: 0;
  }

  &:focus:-ms-input-placeholder {
    opacity: 0;
  }

  &:hover {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none !important;
    border: 1px solid #DCDFE3;
  }

  &:focus {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none !important;
    border: 1px solid #B4B8BE !important;
  }

  &:active {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none !important;
    border: 1px solid #DCDFE3;
  }
`
const Label = styled.div`
  font-size: 13px;
  color: ${colors.colors.font}; 
  font-weight: 400;
  padding-bottom: 4px;
`
const Error = styled.div`
  font-size: 11px;
  height: 16px;
  display: flex;
  align-items: flex-end;
  color: ${props => props.color};
`
const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const InptLabel = (props) => {
  return (
      <DivInput>
        <Label>
          {props.label}
        </Label>
        <Inpt
            {...props}
        />
        <Error color={props.colorError}>
          {props.error}
        </Error>
      </DivInput>
  )
}

const getInputNumbersValue = function (input) {
  // Return stripped input value — just numbers
  return input ? input.value.replace(/\D/g, ''): '';
}

export class InptPhone extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      value:''
    }
  }
  onPhoneKeyDown = function (e) {
    // Clear input after remove last symbol
    const inputValue = e.target.value.replace(/\D/g, '');
    if (e.keyCode === 8 && inputValue.length === 1) {
      e.target.value = "";
    }
  }

  onPhonePaste = function (e) {
    const input = e.target,
        inputNumbersValue = getInputNumbersValue(input);
    const pasted = e.clipboardData || window.clipboardData;
    if (pasted) {
      const pastedText = pasted.getData('Text');
      if (/\D/g.test(pastedText)) {
        // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
        // formatting will be in onPhoneInput handler
        input.value = inputNumbersValue;
        return null;
      }
    }
  }
  onPhoneInput = function (e) {
    let input = e.target,
        inputNumbersValue = getInputNumbersValue(input),
        selectionStart = input.selectionStart,
        formattedInputValue = "";

    if (!inputNumbersValue) {
      return input.value = "";
    }

    if (input.value.length !== selectionStart) {
      // Editing in the middle of input, not last symbol
      if (e.data && /\D/g.test(e.data)) {
        // Attempt to input non-numeric symbol
        input.value = inputNumbersValue;
      }
      return;
    }

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
      if (inputNumbersValue[0] === "9") inputNumbersValue = "7" + inputNumbersValue;
      const firstSymbols = (inputNumbersValue[0] === "8") ? "8" : "+7";
      formattedInputValue = input.value = firstSymbols + " ";
      if (inputNumbersValue.length > 1) {
        formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
      }
    } else {
      formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
    }
    input.value = formattedInputValue;
  }

  componentDidMount(e) {
    this.setState(()=>{
      return {value: getInputNumbersValue(this.props.state)}
    })
    // document.addEventListener('keydown', this.onPhoneKeyDown )
    // document.addEventListener('input', this.onPhoneInput, false);
    // document.addEventListener('paste', this.onPhonePaste, false);
  }

  render() {
    return(
        <InptLabel
            {...this.props}
            data-tel-input
            onKeyDown={this.onPhoneKeyDown}
            onInput={this.onPhoneInput}
            onPaste={this.onPhonePaste}
        />
    )
  }
}
