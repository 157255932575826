import axios from 'axios';
import config from "./config";

export const axiosConfig = (token) => {
  const {baseUrl, headers} = config.depends
  return {
    baseURL: baseUrl,
    withCredentials: true,
    headers: headers(token)
  }
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const nowTimeServer = async () => {
  const response = await fetch(`${config.depends.baseUrl}/time-now`)
  const result = await response.json()
  return result.data
}

export const setToken = (token) => (
    window.localStorage.setItem('x-token', token)
)

export const getToken = () => (
    window.localStorage.getItem('x-token')
)

export const removeToken = () => (
    window.localStorage.removeItem('x-token')
)

let tokenUpdating = false

export async function getRefresh(xToken) {
  const token = parseJwt(xToken)
  tokenUpdating = true
  const nowTime = await nowTimeServer()
  if (!xToken || !token) {
    return tokenUpdating = false
  }
  if (token.exp - 55 < nowTime) {
    const instance = axios.create(axiosConfig(xToken))
    const response = async () => await instance.get(config.endpoints.refresh_token.endpoint)
    try {
      const {status, headers} = await response()
      if (status === 200) {
        setToken(headers['x-token'])
      } else {
        removeToken()
      }
    } catch (e) {
      removeToken()
    }
    return tokenUpdating = false
  }
  return tokenUpdating = false
}


export const http = async (isAuth) => {
  if (isAuth) {
    if (!tokenUpdating) {
      await getRefresh(getToken())
    } else {
      while (tokenUpdating) {
        await new Promise(r => setTimeout(r, 10));
      }
    }
  }
  return axios.create(axiosConfig(getToken()))
}

export async function instance(config, data) {
  const api = await http(config.isAuth)
  switch (config.method) {
    case 'post':
      return api.post(config.endpoint, data)
    case 'put':
      return api.put(config.endpoint, data)
    case 'head':
      return api.head(config.endpoint)
    default:
      if (data){
        data = {'params': data}
        return api.get(config.endpoint, data)
      }
      return api.get(config.endpoint)
  }
}
