import React from 'react'
import {Navigate} from "react-router-dom";
import {getToken, parseJwt} from "../api/http-util";


const PrivateRoute = ((props) => {
  const token = (() => parseJwt(getToken()))()
  const isAuth = !!token && !!token.rle && token.rle !== 'anonymous'
  if (!isAuth) {
    return <Navigate to={'/login'}/>
  }
  return props.children
})
export default PrivateRoute;
