import React from "react"
import styled from "styled-components"
import {IconHelp, IconOper, IconReport, IconSprav} from "../../components/icons";
import {useNavigate} from "react-router-dom";

function LeftPanel() {
  const navigate = useNavigate()
  return (
      <>
        <NavLeft>
          <ItemWrapper>
            <Item>
              <IconOper/>
            </Item>
            <Item>
              <IconReport/>
            </Item>
            <Item>
              <IconSprav/>
            </Item>
          </ItemWrapper>
          <div>
            <Item onClick={()=>navigate('/start')}>
              <IconHelp/>
            </Item>
          </div>
          <NavLeftAddon>
            <ItemWrapper>
              <Item>
                <TextItem>
                  Операции
                </TextItem>
              </Item>
              <Item>
                <TextItem>
                  Отчеты
                </TextItem>
              </Item>
              <Item>
                <TextItem>
                  Справочники
                </TextItem>
              </Item>
            </ItemWrapper>
            <div>
              <Item onClick={()=>navigate('/start')}>
                <TextItem>
                  Помощь
                </TextItem>
              </Item>
            </div>
          </NavLeftAddon>
        </NavLeft>
      </>
  )
}

export default LeftPanel

const NavLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: #000;
  width: 60px;
  height: 100%;
  top: 0;
  z-index: 997 !important;
`
const NavLeftAddon = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  width: 0;
  height: 100%;
  top: 0;
  left: 60px;
  z-index: 1000 !important;
  transition: width 0.3s;
  overflow: hidden;

  &hover {
    width: 120px;
  }

  ${NavLeft}:hover & {
    width: 120px;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 18px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`
const TextItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
`