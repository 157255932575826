import React from "react";
import {InptLabel, InptPhone} from "../../components/inpt";
import styled from "styled-components";
import {ClickSpan} from "../../components/anchor";
import {Button} from "../../components/btn";
import * as yup from "yup";
import {useFormik} from "formik";
import config from "../../api/config";
import {instance, setToken} from "../../api/http-util";
import {useNavigate} from "react-router-dom";

const validationSchema = (entryPhone) => yup.object({
  username: !entryPhone
      ? yup.string().email('Введите корректный e-mail').required('Введите e-mail')
      : yup.string().required('Введите номер телефона'),
  password: yup.string().min(6, 'Пароль должен быть не менее 6 символов').required('Введите пароль')
})

function LoginForm({entryPhone, phoneClick}) {

  const [isFocus, setIsFocus] = React.useState(false)
  const [errorResponse, setError] = React.useState('')
  const navigate = useNavigate()
  const onSubmit = () => {
    const data = new FormData()
    data.append('username', formik.values.username)
    data.append('password', formik.values.password)
    const endpoint = entryPhone
        ? config.endpoints.login_phone
        : config.endpoints.login_email

    instance(endpoint, data)
        .then(response => {
          const {status, headers} = response
          if (status === 200) {
            setToken(headers['x-token'])
            formik.handleReset(undefined)
            navigate('/')
          }
        })
        .catch( error => {
          const {data} = error.response
          setError(data.detail)

        }
    )
  }
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema(entryPhone),
  })

  React.useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isFocus) {
          formik.handleSubmit()
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik, isFocus]);
  return (
      <form onSubmit={formik.handleSubmit}>
        {!entryPhone
            ? <InptLabel
                type={'text'}
                name={'username'}
                label={'Логин'}

                placeholder={'Введите ваш e-mail'}
                colorError={'red'}
                onBlur={formik.handleBlur}

                onChange={e => {
                  formik.handleChange(e)
                  formik.setSubmitting(false)
                }}
                value={formik.values.username}
                error={formik.touched.username && formik.errors.username
                    ? formik.errors.username
                    : ''}
                onFocus={() => {
                  setError('')
                }}
            />
            : <InptPhone
                type={'tel'}
                name={'username'}
                label={'Номер телефона'}
                placeholder={'Введите номер телефона'}
                colorError={'red'}
                onBlur={formik.handleBlur}
                onChange={e => {
                  formik.handleChange(e)
                  formik.setSubmitting(false)
                }}
                value={formik.values.username}
                error={formik.touched.username && formik.errors.username
                    ? formik.errors.username
                    : ''}
                onFocus={() => {
                  setError('')
                }}
            />}
        <Div1/>
        <ClickSpan
            red
            onClick={() => {
              phoneClick()
              return formik.handleReset(undefined)
            }}
            style={{textAlign: 'center'}}
        >
          {entryPhone
              ? 'Войти по e-mail'
              : 'Войти по номеру телефона'}
        </ClickSpan>
        <Div1/>
        <InptLabel
            type={'password'}
            name={'password'}
            label={'Пароль'}
            placeholder={'Введите пароль'}
            autoComplete='on'
            colorError={'red'}
            onBlur={e => {
              formik.handleBlur(e)
              return setIsFocus(false)
            }}
            onChange={e => {
              formik.handleChange(e)
              formik.setSubmitting(false)
            }}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password
                ? formik.errors.password
                : errorResponse}
            onFocus={() => {
              setIsFocus(true)
              setError('')
            }}
        />
        <Div1/>
        <Button
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            type={'submit'}
            // onClick={''}
        >
          Войти
        </Button>
      </form>
  )
}

export default LoginForm


const Div1 = styled.div`
  height: 10px;
  @media (max-height: 568px) {
    height: 4px;
  }
`
