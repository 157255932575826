import React from 'react';
import {logo} from "../../components/icons";
import {ClickSpan} from "../../components/anchor";
import styled from "styled-components";
import LoginForm from "./login-form";
import PasswordRecoveryStep1 from "./modal/password-recovery/step1-email";
import PasswordRecoveryStep2 from "./modal/password-recovery/step2-code";
import RegistrationStep1 from "./modal/registration/step1-data";
import RegistrationStep2 from "./modal/registration/step2-code";
import NewPassword from "./modal/new-password";
import LoginContext from "./context";
import config from "../../api/config";
import {instance} from "../../api/http-util";

const windowOpen = async (windowModal) => {
  switch (windowModal) {
    case 0: {
      const {status, headers} = await instance(config.endpoints.start_session)
      if (status === 200) {
        return window.localStorage.setItem('x-token', headers['x-token'])
      }
      return;
    }
    case 1: {
      const {status, headers} = await instance(config.endpoints.password_recovery)
      if (status === 200) {
        return window.localStorage.setItem('x-token', headers['x-token'])
      }
      return;
    }
    case 2: {
      const {status, headers} = await instance(config.endpoints.registration)
      if (status === 200) {
        return window.localStorage.setItem('x-token', headers['x-token'])
      }
      return;
    }
    default:
      return;
  }
}


function Login() {
  const [isPhone, setIsPhone] = React.useState(false)
  const [window, setWindow] = React.useState(0)

  React.useEffect(() => {
    // 0 - все закрыты, 1 - востановить пароль, 2 - регистрация
    // 11 - введение кода (востановить пароль)
    // 21 - введение кода (регистрация)
    // 3 - установка пароля
    windowOpen(window).then(() => {
    })
  }, [window])
  return (
      <LoginContext.Provider value={{window, setWindow}}>
        <Wrapper>
          <Logo/>
          <WrapperOpacity>
            <Container>
              <Header>
                <div>
                  Cloud
                </div>
                <SeparatorH/>
                <div>
                  CASH MANAGEMENT
                </div>
              </Header>
              <div style={{height: 36}}/>
              <DivLoginForm>
                <LoginForm
                    entryPhone={isPhone}
                    phoneClick={() => setIsPhone(!isPhone)}
                />
              </DivLoginForm>
              <div style={{height: 37}}/>
              <DivReg>
                <ClickSpan onClick={() => setWindow(1)}>
                  Восстановить пароль
                </ClickSpan>
                <SeparatorW/>
                <RegSpan red onClick={() => setWindow(2)}>
                  Зарегистрироваться
                </RegSpan>
              </DivReg>
            </Container>
            <UserContract>
              <ClickSpan style={{textAlign: 'center', paddingLeft: 34}}>
                Политика конфендициальности
              </ClickSpan>
              <SeparatorW/>
              <ClickSpan style={{textAlign: 'center'}}>
                Пользовательское соглашение
              </ClickSpan>
              <SeparatorW/>
              <span style={{fontSize: 13}}>
                © 2020-2022 CloudCM - Cash Management Company
              </span>
              <div style={{height: 42}}/>
            </UserContract>
            <RegSpan1024 red onClick={() => setWindow(2)}>
              Зарегистрироваться
            </RegSpan1024>
          </WrapperOpacity>
          <PasswordRecoveryStep1 open={window}/>
          <PasswordRecoveryStep2 open={window}/>
          <RegistrationStep1 open={window}/>
          <RegistrationStep2 open={window}/>
          <NewPassword open={window}/>
        </Wrapper>
      </LoginContext.Provider>
  )
}

export default Login

const RegSpan = styled(ClickSpan)`
  @media (max-width: 321px) {
    display: none;
  }
`
const RegSpan1024 = styled(ClickSpan)`
  display: none;
  bottom: 27px;
  left: 27px;
  position: absolute;
  width: 100%;
  z-index: 1000;

  @media (max-width: 321px) {
    display: flex;
  }
  //@media (max-height: 720px) {
  //  display: none;
  //}
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const Logo = styled.div`
  display: inline-block;
  top: 25%;
  left: 5%;
  position: absolute;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  width: 50%;
  height: 75%;
  z-index: 0;
  @media (max-width: 568px) {
    background-size: cover;
    transform: rotate(180deg);
    left: 0;
    top: 100px;
    height: 60%;
  }
  @media (max-height: 374px) {
    background-size: cover;
    top: 0;
    height: 100%;
  }
`
const WrapperOpacity = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.9);

`
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;

`
const Header = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 28px;
  @media (max-height: 568px) {
    font-size: 18px;
    padding-left: 105px;
    padding-top: 30px;
  }
  @media (max-width: 321px) {
    padding-left: 90px;
    font-size: 18px;
  }
`
const SeparatorH = styled.div`
  height: 3px;
  @media (max-height: 568px) {
    height: 0;
  }
  @media (max-width: 321px) {
    height: 0;
  }
`
const SeparatorW = styled.div`
  height: 17px;
  width: 0;
  border-left: 1px solid grey;
  margin: 0 13px 0 14px;
  @media (max-width: 321px) {
    display: none;
  }
  //@media (max-height: 720px) {
  //  display: none;
  //}
`
const DivLoginForm = styled.div`
  width: 282px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 321px) {
    width: 270px;
  }
`
const DivReg = styled.div`
  display: flex;
  @media (max-height: 568px) {
    display: none;
  }
`
const UserContract = styled.div`
  display: flex;
  width: 100%;
  z-index: 1000;

  & span {

    color: #fff;
  }

  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-height: 720px) {
    display: none;
  }
`
