import React from "react";
import styled from "styled-components";
import {H20} from "./heads";



function ContentCard(props){
  return (
      <StyledWrapper w={props.w} {...props}>
        <H20>
          {props.head}
        </H20>
        <StyledContentCard>
          {props.children}
        </StyledContentCard>
      </StyledWrapper>
  )
}

export default ContentCard



const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.w ? props.w + 'px': 'auto'};
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
`
const StyledContentCard = styled.div`
  display: inline-flex;
  padding: 32px 48px ;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0,0,0,0.125);
`
