import styled from "styled-components";
import {H1Modal} from "./heads";
import colors from "../config/style.json";
import Modal from "./modal";
import {menuClose} from "./icons";
import React, {useEffect, useState} from "react";


const H1Msg = styled(H1Modal)`
  @media (max-width: 371px) {
    padding-bottom: 23px;
  }
`
const DivMsg = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${colors.colors.font};
`
const DivModalClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  right: 27px;
  top: 27px;
  & img {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
`
const ModalMsg = ((props) =>{
  const [show, setShow] = useState(false)
  useEffect(()=>{
    setShow(props.show)
  },[props.show])
  return(
      <Modal show={show} w={434}>
        <H1Msg>
          {props.header}
        </H1Msg>
        <DivMsg >
          {props.children}
        </DivMsg>
        <DivModalClose onClick={()=>props.onClose()}>
          <img src={menuClose} alt={'logo'} />
        </DivModalClose>
      </Modal>
  )
})
export default ModalMsg