import styled from "styled-components";

export const H1Modal = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #000000;
  padding-bottom: 27px;
  @media (max-width: 371px) {
    font-size: 21px;
    width: 200px;
    font-weight: 500;
    line-height: 24px;
  }
`
export const H20 = styled.h1`
  font-size: 20px;
  color: black;
  font-weight: 400;
  margin: 0;
  padding-bottom: 12px;
`