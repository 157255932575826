import React, {useContext, useState} from "react";
import styled from "styled-components";
import {InptLabel} from "../../components/inpt";
import {ClickSpan} from "../../components/anchor";
import {Button, ButtonSecondary} from "../../components/btn";
import colors from "../../config/style.json"
import {useFormik} from "formik";
import * as yup from "yup";
import ModalMsg from "../../components/modal-msg";


import MainContext from "../main/context";
import {instance} from "../../api/http-util";
import config from "../../api/config";
import {CheckBox} from "../../components/checkbox";


const validationSchema = yup.object({})

function ProfileForm() {
  const [showPhoneChange, setShowPhoneChange] = useState(false)
  const [responseError, setResponseError] = useState('')
  const {profile, setProfile, user, settings} = useContext(MainContext)
  const {id, email, phone} = user
      ? user
      : {}
  const {f_name, l_name, p_name} = profile
      ? profile
      : {}
  const idCut = (id) => {
    if (id) {
      return id.slice(0, 7)
    } else {
      return ''
    }
  }
  const formatPhone = (phone) => {
    let formattedPhone = ''
    if (!!phone && !isNaN(phone)) {
      if (phone.length >= 10) {
        formattedPhone += '+' + phone.slice(0, 1)
        formattedPhone += '(' + phone.slice(1, 4)
        formattedPhone += ') ' + phone.slice(4, 7)
        formattedPhone += '-' + phone.slice(7, 9)
        formattedPhone += '-' + phone.slice(9, 11)
        return formattedPhone
      } else {
        return phone
      }
    } else {
      return ''
    }
  }
  const handleChangeCheckBox = (event) => {
    const data = {
      user_settings: {
        notStartPage: !event.target.checked
      }
    }
    instance(config.endpoints.set_settings, data)
        .then(() => {
        })
        .catch(() => {
        })
  }
  function onSubmit() {
    const data = new FormData()
    data.append('user_id', id)
    data.append('f_name', formik.values.f_name)
    data.append('l_name', formik.values.l_name)
    data.append('p_name', formik.values.p_name)
    instance(config.endpoints.profile_update, data)
        .then(() => {
          setProfile({
            'f_name': formik.values.f_name,
            'l_name': formik.values.l_name,
            'p_name': formik.values.p_name,
          })
        })
        .catch(e => {
          const {data} = e.response
          setResponseError(data.detail)
          formik.resetForm()
        })
  }


  const formik = useFormik({
    initialValues: {
      id: idCut(id),
      email: '',
      phone: formatPhone(phone),
      f_name: f_name || '',
      l_name: l_name || '',
      p_name: p_name || '',
    },
    validateOnBlur: true,
    onSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  return (
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <InptLabel
                label={'ID'}
                disabled
                value={idCut(id)}
                type={'text'}
                name={'ID'}
            />
          </Col>
          <div style={{padding: '0 24px'}}/>
          <Col>
            <InptLabel
                label={'E-mail'}
                disabled
                defaultValue={email}
                // value={'sadfasdf'}

                type={'text'}
                name={'email'}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <InptLabel
                  label={'Телефон'}
                  disabled
                  defaultValue={formatPhone(phone)}
                  type={'tel'}
              />
            </Row>
            <Row style={{justifyContent: 'center'}}>
              <ClickSpan red style={{margin: '-6px 0 20px 0'}}
                         onClick={() => setShowPhoneChange(true)}
              >Изменить номер телефона</ClickSpan>
            </Row>
            <ModalMsg
                header={'Изменить номер телефона'}
                show={showPhoneChange}
                onClose={() => setShowPhoneChange(false)}
            >
              Данная функция сейчас не доступна
            </ModalMsg>
          </Col>
          <div style={{padding: '0 24px'}}/>
          <Col>
            <div style={{width: '100%'}}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <InptLabel label={'Фамилия'}
                       type={'text'}
                       name={'f_name'}
                       value={formik.values.f_name}
                       onChange={formik.handleChange}
            />
          </Col>
          <div style={{padding: '0 24px'}}/>
          <Col>
            <InptLabel label={'Имя'}
                       name={'l_name'}
                       value={formik.values.l_name}
                       onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InptLabel label={'Отчество'}
                       name={'p_name'}
                       value={formik.values.p_name}
                       onChange={formik.handleChange}
                       error={
                         responseError
                             ? responseError
                             : null
                       }
            />
          </Col>
          <div style={{padding: '0 24px'}}/>
          <Col style={{justifyContent: 'center'}}>
            <CheckBoxDiv style={{width: 233, height: 36}}>
              <CheckBox
                  defaultChecked={!settings.notStartPage}
                  onChange={handleChangeCheckBox}
              />
              <span className={'labelCheck'}>
                  Показывать стартовую страницу при входе
              </span>
            </CheckBoxDiv>
          </Col>
        </Row>
        <Row>
          <WrapperButton>
            <div>
              <ButtonSecondary
                  type={'button'}
                  onClick={() => formik.resetForm()}
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              >
                Отменить
              </ButtonSecondary>
            </div>
            <div style={{width: 18}}/>
            <div>
              <Button
                  type={'submit'}
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              >
                Сохранить
              </Button>
            </div>
          </WrapperButton>
        </Row>
      </form>
  )
}

export default ProfileForm

const CheckBoxDiv = styled.div`
  display: flex;
  width: 233px;
  height: 36px;
  align-items: center;

  & .labelCheck {
    margin-left: 5px;
    line-height: 150%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const WrapperButton = styled.div`
  display: flex;
  background-color: ${colors.colors.btnInline};
  border: 1px solid ${colors.colors.btnInline};
  width: 100%;
  border-radius: 7px;
  padding: 18px;
  margin-top: 28px;
  justify-content: flex-end;
  flex-direction: row;

  & div {
    width: 165px;
  }
`