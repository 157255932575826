import React, {useEffect, useState} from "react";
import ContentCard from "../../components/contentcard";
import ProfileForm from "./profile-form";
import styled from "styled-components";
import {IconsSettings} from "../../components/icons";
import {ClickSpan} from "../../components/anchor";
import  ChangePassword from "./cange-password"
import ModalMsg from "../../components/modal-msg";

const SettingsMenu = React.forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  useEffect(()=>{
    setShow(props.show)
  }, [props.show])
  function handleChangePassword() {
    props.handleChangePassword()
  }
  function handleDeleteProfile() {
    props.handleDeleteProfile()
  }
  return (
      <StyledSettingsMenu show={show} ref={ref}>
        <Item
            onClick={() => handleChangePassword()}>
          <ClickSpan>
            Сменить пароль
          </ClickSpan>
        </Item>
        <Item onClick={() => handleDeleteProfile()}>
          <ClickSpan red>
            Удалить аккаунт
          </ClickSpan>
        </Item>
      </StyledSettingsMenu>
  )
})
const menuRef = React.createRef()

function Profile() {
  const ButtonSettingsRef = React.useRef(null)

  const [menuSettings, setMenuSettings] = React.useState(false)
  const [showPasswordChange, setShowPasswordChange] = React.useState(false)
  const [showDeleteProfile, setShowDeleteProfile] = React.useState(false)
  const onClickOutMenuSettings = (e) => {
    if (!menuRef.current.contains(e.target) && !ButtonSettingsRef.current.contains((e.target))) {
      setMenuSettings(false)
    }
  }
  const handleChangePassword = () => {
    setShowPasswordChange(true)
  }
  const handleDeleteProfile = () => setShowDeleteProfile(true)
  React.useEffect(() => {
    document.addEventListener('click', onClickOutMenuSettings, true);
    return () => {
      document.removeEventListener('click', onClickOutMenuSettings, true);
    }
  })
  // React.useEffect(()=>{
  //   const data = {
  //     user_settings: {
  //       openPage: "/profile"
  //     }
  //   }
  //   instance(config.endpoints.set_settings, data)
  //       .then(()=>{})
  //       .catch(()=>{})
  // })
  return (
      <>
        <ContentCard head={'Мой профиль'} w={830} style={{minWidth: 830}}>
          <Wrapper>
            <Row style={{justifyContent: 'flex-end'}}>
              <DivIconSettings onClick={() => setMenuSettings(!menuSettings)} ref={ButtonSettingsRef}>
                <IconsSettings/>
                <SettingsMenu
                    show={menuSettings}
                    ref={menuRef}
                    handleChangePassword={()=>handleChangePassword()}
                    handleDeleteProfile={()=>handleDeleteProfile()}
                />
              </DivIconSettings>
            </Row>
            <ProfileForm/>
          </Wrapper>
        </ContentCard>
        <ChangePassword
            show={showPasswordChange}
            onClose={()=>setShowPasswordChange(false)}
        >
        </ChangePassword>
        <ModalMsg
            header={'Удаление аккаунта'}
            show={showDeleteProfile}
            onClose={()=>setShowDeleteProfile(false)}
        >
          Данная функция сейчас не доступна
        </ModalMsg>
      </>
  )
}

export default Profile

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //min-width: 830px;
`
const DivIconSettings = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 7px;
  margin: -9px -24px 9px 0;
  cursor: pointer;

  &:hover {
    box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
  }

  &:active {
    box-shadow: none;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledSettingsMenu = styled.div`
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  position: absolute;
  overflow: hidden;
  top: 26px;
  right: 0;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
  padding: 15px 0 19px 0;
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  transform: ${props => props.show ? 'scale(1)' : 'scale(0.9)'};;
  opacity: ${props => props.show ? 1 : 0};
`
const Item = styled.div`
  position: relative;
  cursor: pointer;
  padding: 10px 48px 10px 28px;

  & span {
    font-size: 14px !important;
    white-space: nowrap;
  }

  &:hover {
    background-color: #F1F3F4;
  }
`