import React from "react";
import {ClickSpan} from "../../../components/anchor";

function Timer(props) {
  //seconds - время в секундах

  const [counter, setCounter] = React.useState(0);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    let isMounted = true
    if (isMounted){
      setCounter(props.seconds)
      setShow(props.show)
    }
    return ()=>{isMounted=false}
  }, [props.seconds, props.show])
  React.useEffect(() => {
    let isMounted = true
    if (show && isMounted) {
      const count = setInterval(
          function () {
            setCounter(counter - 1)
            clearInterval(count)
            if (counter <= 2) {
              setCounter(props.seconds)
              props.callbackTimer(false)
              setShow(false)
            }
          }, 1000)
    }
    return () => {isMounted = false}
  }, [counter, show, props]);

  if (!show) {
    return (
        <ClickSpan
            style={{fontWeight: 400}}
            red
            onClick={() => props.onClick()}>
          Получить новый код
        </ClickSpan>
    )
  } else {
    return (
        <>
          {counter < 1 && `${props.text} 1 сек.`}
          {counter >= 1 && `${props.text} ${counter} сек.`}
        </>
    )
  }
}

export default Timer