import React, {useEffect, useState} from 'react'
import PrivateRoute from "../../components/private";
import {Route, Routes, useNavigate, useLocation} from "react-router-dom";
import Profile from "../profile";
import config from "../../api/config";
import {instance} from "../../api/http-util";
import MainContext from "./context";
import TopPanel from "./top-panel";
import styled, {keyframes} from "styled-components";
import SpinSVG from "../../static/img/spin.svg"
import StartPage from "../start";
import Dashboard from "../dashboard";
import Company from "../company";


function Main() {
  const [profile, setProfile] = useState({})
  const [settings, setSettings] = useState({})
  const [user, setUser] = useState({})
  const [load, setLoad] = useState(false)
  // console.log(settings)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    instance(config.endpoints.get_user)
        .then(r => {
          const {data} = r
          setUser(data)
        })
        .catch(() => {
          navigate('/login')
        })
    instance(config.endpoints.get_profile)
        .then(r => {
          const {data} = r
          if (!data) {
            navigate('/login')
          }
          const {f_name, l_name, p_name, user_settings} = data
          setProfile(prevState => ({
            ...prevState,
            f_name: f_name,
            l_name: l_name,
            p_name: p_name,
          }))
          const notStartPage = JSON.parse(user_settings).notStartPage === 'True'

          setSettings(prevState => ({
            ...prevState,
            openPage: JSON.parse(user_settings).openPage,
            notStartPage: notStartPage,
          }))
          // return console.log(notStartPage)
        })
        .then(() => {
              setLoad(true)
            }
        )
        .catch(() => {
          navigate('/login')
        })
  }, [navigate])
  useEffect(() => {
    const path = location.pathname in {'/login':'', '/':'', '/start':'', '/board':''}
    if (!path) {
      const data = {
        user_settings: {
          openPage: location.pathname
        }
      }
      instance(config.endpoints.set_settings, data)
          .then(() => {
          })
          .catch(() => {
          })
    }
  }, [location.pathname])
  useEffect(() => {
    if (!load){
        if (!settings.notStartPage) {
            navigate("/start")
        } else {
            navigate("/board")
        }
    }
  },[load, navigate, settings.notStartPage])

  if (!load) {
    return <Spinner/>
  }
  return (
      <MainContext.Provider value={{
        profile, setProfile,
        user, setUser,
        settings, setSettings
      }}>
        <PrivateRoute>
          <TopPanel/>
          <div className={"wrapper"}>
            <Routes>
              <Route element={<Profile/>} path={"/user-profile"}/>
              <Route element={<Company/>} path={"/company"}/>
              <Route element={<StartPage/>} path={"/start"}/>
              <Route element={<Dashboard/>} path={"/board"}/>
            </Routes>
          </div>
        </PrivateRoute>
      </MainContext.Provider>
  )
}

export default Main

const Spinner = () => {
  return (
      <>
        <Load>
          <div style={{margin: 'auto'}}>
            <SpinnerImg src={SpinSVG}/>
            <div style={{color: '#595E69', fontSize: 11, fontWeight: 600}}>CASH MANAGEMENT</div>
            <div>loading...</div>
          </div>
        </Load>
      </>
  )
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const SpinnerImg = styled.img`
  animation: 5s linear 0.2s normal none infinite running ${spin};
  -webkit-animation: 5s linear 0.2s normal none infinite running ${spin};
`
const Load = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 13px;
  color: rgba(130, 0, 0, 1);
  height: 100%;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", "Liberation Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
`
