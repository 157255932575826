
const headers = (token) => {
  return{
    "content-type": "application/x-www-form-urlencoded",
    "accept": "application/json",
    "x-token": `${token}`,
  }
}

const config = {
  depends: {
    // baseUrl: "https://dev.local/api/v1",
    baseUrl: "https://cloudcm.ru/api/v1",
    responseType: "json",
    headers: headers,
  },
  endpoints: {
    start_session: {
      isAuth: false,
      method: "get",
      endpoint: "/logout"
    },
    refresh_token: {
      isAuth: false,
      method: "get",
      endpoint: "/token-update"
    },
    registration: {
      isAuth: false,
      method: "get",
      endpoint: "/registration"
    },
    registration_data: {
      isAuth: false,
      method: "post",
      endpoint: "/registration/data"
    },
    registration_code: {
      isAuth: false,
      method: "post",
      endpoint: "/registration/code"
    },
    registration_code_renew: {
      isAuth: false,
      method: "post",
      endpoint: "/registration/code-renew"
    },
    password_recovery: {
      isAuth: false,
      method: "get",
      endpoint: "/password-recovery"
    },
    password_recovery_email: {
      isAuth: false,
      method: "post",
      endpoint: "/password-recovery/email"
    },
    password_recovery_code: {
      isAuth: false,
      method: "post",
      endpoint: "/password-recovery/code"
    },
    password_recovery_code_renew: {
      isAuth: false,
      method: "post",
      endpoint: "/password-recovery/code-renew"
    },
    login_email: {
      isAuth: false,
      method: "post",
      endpoint: "/email"
    },
    login_phone: {
      isAuth: false,
      method: "post",
      endpoint: "/phone"
    },
    get_profile: {
      isAuth: true,
      method: "get",
      endpoint: "/profile"
    },
    get_user: {
      isAuth: true,
      method: "get",
      endpoint: "/user-data"
    },
    profile_update:{
      isAuth: true,
      method: "put",
      endpoint: "/profile/update"
    },
    password_update: {
      isAuth: true,
      method: "post",
      endpoint: "/password-update"
    },
    set_settings:{
      isAuth: true,
      method: "put",
      endpoint: "/settings/update"
    },
    get_setting:{
      isAuth: true,
      method: "get",
      endpoint: "/settings"
    }
  }
}

export default config