import React from "react";
import Modal from "../../../../components/modal";
import {H1Modal} from "../../../../components/heads";
import styled from "styled-components";
import colors from "../../../../config/style.json"
import {InptLabel} from "../../../../components/inpt";
import {Button, ButtonSecondary} from "../../../../components/btn";
import {useFormik} from "formik";
import LoginContext from "../../context";
import * as yup from "yup";
import {instance} from "../../../../api/http-util";
import config from "../../../../api/config";


function PasswordRecoveryStep1({open}) {
  return (
      <>
        <Modal show={open === 1} w={434}>
          <H1Modal>
            Восстановление пароля
          </H1Modal>
          <Form/>
        </Modal>
      </>
  )
}

export default PasswordRecoveryStep1

const validationSchema = yup.object({
  email: yup.string().email('Введите корректный e-mail').required('Введите e-mail')
})

function Form() {
  const {setWindow} = React.useContext(LoginContext)
  const [isFocus, setIsFocus] = React.useState(false)
  const [error, setError] = React.useState('')

  const onSubmit = () => {
    const data = new FormData()
    data.append('email', formik.values.email)
    instance(config.endpoints.password_recovery_email, data)
        .then(r => {
          const {status} = r
          if (status === 200){
            setWindow(11)
            formik.handleReset(undefined)
          }
        })
        .catch(e => {
          const {data} = e.response
          setError(data.detail)
        })
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  })
  React.useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isFocus){
          formik.handleSubmit()
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik, isFocus]);
  return (
      <form onSubmit={formik.handleSubmit}>
        <InptLabel
            type={'text'}
            name={'email'}
            onChange={e => {
              formik.handleChange(e)
              formik.setSubmitting(false)
              return setError('')
            }}
            onBlur={e=> {
              formik.handleBlur(e)
              return setIsFocus(false)
            }}
            error={
              formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : error

            }
            label={'E-mail'}
            value={formik.values.email}
            placeholder={'Введите ваш e-mail'}
            colorError={'red'}
            onFocus={()=>setIsFocus(true)}
        />
        <Div1/>
        <DivMsg>
          Вам будет направлено письмо с одноразовым кодом на зарегистрированный email
        </DivMsg>

        <Div2/>
        <DivBtns>
          <DivBtn>
            <ButtonSecondary
                onClick={() => {
                  setWindow(0)
                  return formik.handleReset(undefined)
                }}
                type={'button'}
            >
              Отмена
            </ButtonSecondary>
          </DivBtn>
          <DivBtn>
            <Button
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                type={'submit'}
            >
              Продолжить
            </Button>
          </DivBtn>
        </DivBtns>
      </form>
  )
}


const Div1 = styled.div`
  height: 10px;
  @media (max-height: 568px) {
    height: 4px;
  }
`
const Div2 = styled.div`
  height: 27px;
  @media (max-height: 568px) {
    height: 20px;
  }
`
const DivBtns = styled.div`
  display: flex;
  justify-content: flex-end;

`
const DivBtn = styled.div`
  width: 131px;
  margin-left: 12px;
`
const DivMsg = styled.div`
  font-size: 13px;
  color: ${colors.colors.font};
  text-align: center;
`