import React, {useContext} from "react";
import MainContext from "../main/context";
import {instance} from "../../api/http-util";
import config from "../../api/config";
import styled from "styled-components";
import IMG from "../../static/img/spin.svg";
import {IconDownArrow} from "../../components/icons";
import {CheckBox} from "../../components/checkbox";
import {FuncCard} from "./components/cars";
import Diagram from "./components/diagram";

export default function StartPage() {
  const {settings} = useContext(MainContext)
  const handleChangeCheckBox = (event) => {
    const data = {
      user_settings: {
        notStartPage: !event.target.checked
      }
    }
    instance(config.endpoints.set_settings, data)
        .then(() => {
        })
        .catch(() => {
        })
  }
  return(
      <>
        <Wrapper>
          <div className={'header'}>
            <HeaderText1>
              ДОБРО ПОЖАЛОВАТЬ
              в
              <DivImg>
                <img src={IMG} alt=""/>
              </DivImg>
              <HeaderText2>
                Cloud <br/>
                CASH MANAGEMENT!
              </HeaderText2>
            </HeaderText1>
          </div>
          <div className={'readme'}>
            <div style={{fontWeight: 500, color: 'rgba(0,0,0,1)'}}>
              Для работы с сервисом вам необходимо
            </div>
            <div style={{fontWeight: 500, color: 'rgba(0,0,0,1)'}}>
              настроить свой личный кабинет:
            </div>
            <br/>
            <div>
              Кликайте на функционал в схеме и получайте
              подсказки.
            </div>
            <br/>
            <div>
                <span>
                  После подключения или использования функционала он будет отмечен как
                </span>
                <span style={{position: 'relative'}}>
                  <DivIconText>
                    <IconDownArrow/>,
                  </DivIconText> подключенный
                </span>
            </div>
            <div>
              следующие этапы станут активными.
            </div>
            <br/><br/>
            <div className={'exampleButton'}>
              <div className={'one'}>
                <FuncCard status={'Down'}>
                  Подключено
                </FuncCard>
              </div>
              <div className={'two'}>
                <FuncCard status={'Process'}>
                  <span> Доступно</span>
                  <span> для подключения</span>
                </FuncCard>
              </div>
              <div className={'three'}>
                <FuncCard status={'Tobe'}>
                  <span> Не доступно</span>
                  <span> для подключения</span>
                </FuncCard>
              </div>
            </div>
          </div>

          <div className={'checkbox'}>
            <CheckBoxDiv style={{width: 233, height: 36}}>
              <CheckBox
                  defaultChecked={!settings.notStartPage}
                  onChange={handleChangeCheckBox}
              />
              <span className={'labelCheck'}>
                    Показывать страницу приветсвия при входе
                </span>
            </CheckBoxDiv>
          </div>
          <div className={'diagram'}>
            <Diagram/>
          </div>
        </Wrapper>
      </>
  )
}


const CheckBoxDiv = styled.div`
  display: flex;
  width: 233px;
  height: 36px;
  align-items: center;

  & .labelCheck {
    margin-left: 5px;
    line-height: 138%;

  }
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 387px 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  width: 100%;
  max-width: 1024px;
  margin: auto;
  & div {
    //border: 1px solid red;
  }
  & .header{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  & .readme{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    //padding-left: 50px;
  }
  & .exampleButton{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    border: 1px dashed rgba(0,0,0,0.125);
    //padding-left: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 60px;
    & div{
      display: flex;
      
    }
    & .one {
      justify-content: flex-end;
      padding-right: 8px;
      align-items: center;
      padding-top: 5px;
    }
    & .two {
      justify-content: start;
      padding-left: 8px;
      align-items: center;
      padding-top: 5px;

    }
    & .three {
      grid-column-start: 1;
      grid-column-end: 3;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
    }
  }
  & .checkbox{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    //padding-left: 50px;
    padding-top: 50px;
  }
  & .diagram{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 5;
  }
`
const DivImg = styled.div`
  position: absolute;
  top: 95px;
  left: -5px;

  & img {
    width: 30px;
    height: 30px;
  }
`
const DivIconText = styled.div`
  position: absolute;
  bottom: 0;
  right: -15px;
`
const HeaderText1 = styled.div`
  width: 348px;
  margin-top: 10px;
  margin-bottom: 60px;
  position: relative;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 145%;
  display: flex;
  align-items: center;
  //text-transform: uppercase;
`
const HeaderText2 = styled.div`
  position: absolute;
  width: 363px;
  height: 96px;
  top: 48px;
  left: 35px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 133%;
  color: #000000;
`