import React from 'react'
import styled from "styled-components"

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 1001;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  visibility:${props => props.show ? 'visible': 'hidden'};
`
const Wrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 415px) {
    justify-content: start;
    
  }
`
const Window = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: ${props => props.w ? props.w + 'px': 'auto'};
  height: ${props => props.h ? props.h + 'px': 'auto'};
  max-width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  transform: ${props => props.show ? 'scale(1)': 'scale(0.9)'};;
  opacity: ${props => props.show ? 1: 0};
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  padding: 27px;
  box-sizing: border-box;
  @media (max-height: 568px) {

  }
  @media (max-width: 415px) {
    margin-top: 79px;
    border-radius: 7px;
    width: calc(100% - 20px);
  }

`

const Modal = React.forwardRef((props, ref) =>{
  let html = document.documentElement
  let scrollPosition = window.pageYOffset
  if (props.show){
    html.style.top = -scrollPosition + "px"
    html.classList.add("modal__opened")
  }else{
    html.classList.remove("modal__opened")
    window.scrollTo(0, scrollPosition)
    html.style.top = ""
  }
  return(
      <Container show={props.show} aria-hidden={'true'}>
        <Wrapper>
          <Window
              className={'modal__window'}
              ref={ref}
              w={props.w}
              h={props.h}
              show={props.show}
              role="dialog"
              aria-modal="true" >
            {props.children}
          </Window>
        </Wrapper>
      </Container>
  )
})
export default Modal