
import styled from "styled-components";
import {H1Modal} from "../../components/heads";
import Modal from "../../components/modal";
import React, {useEffect, useState} from "react";
import * as yup from "yup";

import {useFormik} from "formik";
import {InptLabel} from "../../components/inpt";
import {Button, ButtonSecondary} from "../../components/btn";
import config from "../../api/config";
import {instance} from "../../api/http-util";


const ChangePassword = ((props) => {
  const [show, setShow] = useState(false)
  useEffect(()=>{
    setShow(props.show)
  }, [props.show])
  return (
      <Modal show={show} w={434}>
        <H1Msg>
          Новый пароль
        </H1Msg>
        <ChangePasswordForm
        onClose={()=>props.onClose()}
        />
      </Modal>
  )
})

export default ChangePassword


const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/
const validationSchema = yup.object({
  password1: yup
      .string().min(6, 'Пароль должен быть не менее 6 символов')
      .matches(PASSWORD_REGEX, "Слишком простой пароль")
      .required("Пожалуйста, введите пароль"),
  password2: yup
      .string()
      .required("Пожалуйста, повторите пароль")
      .when("password1", {
        is: (val) => (val && val.length > 0),
        then: yup
            .string()
            .oneOf([yup.ref("password1")], "Пароли не совпадают"),
      }),
})
const ChangePasswordForm = ((props) => {
  const [responseError, setResponseError] = useState()
  const handleCancel = async () => {
    await formik.resetForm()
    props.onClose()
    setResponseError(undefined)
  }
  const onSubmit = () => {
    const data = new FormData()
    data.append('password1', formik.values.password1);
    data.append('password2', formik.values.password2);
    instance(config.endpoints.password_update, data)
        .then(r => {
          const {status} = r
          if (status === 200){
            formik.handleReset(undefined)
            props.onClose()
          }
        })
        .catch(e => {
          const {data} = e.response
          setResponseError(data.detail)
        })
  }

  const formik = useFormik({
    initialValues:{
      password1:'',
      password2:''
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  })
  const handleChange = (e) => {
    formik.handleChange(e)
    formik.setSubmitting(false)
  }
  return(
      <form onSubmit={formik.handleSubmit}>
        <DivFlex>
          <InptLabel
              placeholder="Введите новый пароль"
              label='Пароль'
              colorError={'red'}
              value={formik.values.password1}
              type={'password'}
              name={'password1'}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password1 && formik.errors.password1
                  ? formik.errors.password1
                  : ''
              }
              // onFocus={handleFocus}

          />
          <Div1/>
          <InptLabel
              placeholder="Введите пароль еще раз"
              label='Проверка пароля'
              colorError={'red'}
              value={formik.values.password2}
              type={'password'}
              name={'password2'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password2 && formik.errors.password2
                  ? formik.errors.password2
                  : responseError
                      ? responseError
                      : null
              }
              // onFocus={handleFocus}
          />
        </DivFlex>
        <Div1/>
        <DivBtns>
          <DivBtn>
            <ButtonSecondary
                type ={'button'}
                onClick={()=>handleCancel()}>
              Отмена
            </ButtonSecondary>
          </DivBtn>
          <DivBtn>
            <Button
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                type={'submit'}>
              Продолжить
            </Button>
          </DivBtn>
        </DivBtns>
      </form>
  )

})

const H1Msg = styled(H1Modal)`
  @media (max-width: 371px) {
    padding-bottom: 23px;
  }
`
const Div1 = styled.div`
  height: 10px;
  @media (max-height: 450px) {
    height: 4px;
    width: 16px;
  }
`

const DivBtns = styled.div`
  display: flex;
  justify-content: flex-end;

`
const DivBtn = styled.div`
  width: 131px;
  margin-left: 12px;
`
const DivFlex = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-height: 450px){
    flex-direction: row;
    
  }
`
