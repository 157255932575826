import React from "react";
import {CompanyCards} from "./components/company-card";
import styled from "styled-components";
import WrapperContentPage from "../../components/wrapper-content-page";
import {Inpt} from "../../components/inpt";
import {Button} from "../../components/btn";
import {StyledH} from "../../components/contentcard";
import {H20} from "../../components/heads";

function Company() {
  return (
      <>
        <WrapperContentPage>
          <H20>
            Мои компании
          </H20>
          <PanelCompany>
            <Inpt placeholder={'Поиск'}/>
            <div/>
            <Button>
              Новая компания
            </Button>
          </PanelCompany>
          <WrapperCompanyCard>
            <CompanyCards/>
            <CompanyCards/>
            {/*<CompanyCards/>*/}
            {/*<CompanyCards/>*/}
            {/*<CompanyCards/>*/}
          </WrapperCompanyCard>
        </WrapperContentPage>


      </>
  )
}

export default Company


const WrapperCompanyCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 16px 15px;
  //grid-column-gap: 16px;
  //grid-row-gap: 15px;
`
const PanelCompany = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  padding: 6px 9px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px dashed rgba(0, 0, 0, 0.125);
  border-radius: 7px;
  margin-bottom: 12px;
`