import Modal from "../../../../components/modal";
import {H1Modal} from "../../../../components/heads";
import React from "react";
import {Button, ButtonSecondary} from "../../../../components/btn";
import LoginContext from "../../context";
import * as yup from "yup";
import styled from "styled-components";
import {InptLabel} from "../../../../components/inpt";
import {useFormik} from "formik";
import {instance} from "../../../../api/http-util";
import config from "../../../../api/config";
import colors from "../../../../config/style.json";

function RegistrationStep1({open}) {
  return (
      <>
        <Modal show={open === 2} w={434}>
          <H1Modal>
            Регистрация
          </H1Modal>
          <Form/>
        </Modal>
      </>
  )
}

export default RegistrationStep1

const validationSchema = yup.object({
  f_name: yup.string().min(2, 'Введите фамилию').required('Введите фамилию'),
  l_name: yup.string().min(2, 'Введите имя').required('Введите имя'),
  p_name: yup.string(),
  email: yup.string().email('Введите корректный e-mail').required('Введите e-mail'),
})

function Form() {
  const {setWindow} = React.useContext(LoginContext)
  const [isFocus, setIsFocus] = React.useState(false)
  const [error, setError] = React.useState('')
  const onSubmit = () => {
    const data = new FormData()
    data.append('f_name', formik.values.f_name)
    data.append('l_name', formik.values.l_name)
    data.append('p_name', formik.values.p_name)
    data.append('email', formik.values.email)
    instance(config.endpoints.registration_data, data)
        .then(r => {
          const {status} = r
          if (status === 200){
            setWindow(21)
            formik.handleReset(undefined)
          }
        })
        .catch(e => {
          const {data} = e.response
          setError(data.detail)
        })
  }
  const formik = useFormik({
    initialValues: {
      f_name: '',
      l_name: '',
      p_name: '',
      email: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  })

  React.useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (isFocus){
          formik.handleSubmit()
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik, isFocus]);
  return (
      <form onSubmit={formik.handleSubmit}>
        <DivFlex>
          <InptLabel
              placeholder="Введите фамилию"
              label='Фамилия'
              colorError={'red'}
              value={formik.values.f_name}
              type={'text'}
              name={'f_name'}
              onChange={e => {
                formik.handleChange(e)
                formik.setSubmitting(false)
                return setError('')
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.f_name && formik.errors.f_name
                  ? formik.errors.f_name
                  : ''}


          />
          <Div1/>
          <InptLabel
              placeholder="Введите имя"
              label='Имя'
              colorError={'red'}
              value={formik.values.l_name}
              type={'text'}
              name={'l_name'}
              onChange={e => {
                formik.handleChange(e)
                formik.setSubmitting(false)
                return setError('')
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.l_name && formik.errors.l_name
                  ? formik.errors.l_name
                  : ''}
              inputMode='email'

          />
        </DivFlex>

        <Div1/>
        <DivFlex>
          <InptLabel
              placeholder="Введите отчество"
              label='Отчество'
              colorError={'red'}
              value={formik.values.p_name}
              type={'text'}
              name={'p_name'}
              onChange={e => {
                formik.handleChange(e)
                formik.setSubmitting(false)
                return setError('')
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.p_name && formik.errors.p_name
                  ? formik.errors.p_name
                  : ''}

          />
          <Div1/>
          <InptLabel
              type={'text'}
              name={'email'}
              onChange={e => {
                formik.handleChange(e)
                formik.setSubmitting(false)
                return setError('')
              }}
              onBlur={e=> {
                formik.handleBlur(e)
                return setIsFocus(false)
              }}
              error={
                formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : error
              }
              label={'E-mail'}
              value={formik.values.email}
              placeholder={'Введите ваш e-mail'}
              colorError={'red'}
              onFocus={()=>setIsFocus(true)}
          />
        </DivFlex>
          <DivMsg
            show={formik.touched.email && formik.errors.email
                ? formik.errors.email
                : !!error}
          >
               На этот e-mail будет направлено письмо с одноразовым кодом для подтверждения регистрации
          </DivMsg>
        <Div1/>
        <DivBtns>
          <DivBtn>
            <ButtonSecondary
                onClick={() => {
                  setWindow(0)
                  setError('')
                  return formik.handleReset(undefined)
                }}
                type={'button'}
            >
              Отмена
            </ButtonSecondary>
          </DivBtn>

          <DivBtn>

            <Button
                tabIndex={-1}
                type={'submit'}
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            >
              Продолжить
            </Button>
          </DivBtn>
        </DivBtns>
      </form>
  )
}

const Div1 = styled.div`
  height: 10px;
  @media (max-height: 450px) {
    height: 4px;
    width: 16px;
  }
`

const DivBtns = styled.div`
  display: flex;
  justify-content: flex-end;

`
const DivBtn = styled.div`
  width: 131px;
  margin-left: 12px;
`
const DivFlex = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-height: 450px) {
    flex-direction: row;

  }
`
const DivMsg = styled.div`
  opacity: ${props => !props.show ? 1 : 0};;
  font-size: 11px;
  color: ${colors.colors.font};
  text-align: center;
`