import styled from "styled-components"
import colors from "../config/style.json"

const btn = styled.button`
  min-width: 100px;
  width: 100%;
  height: 38px;
  min-height: 38px;
  border-radius: 7px;
  font-family: Roboto, serif;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  box-sizing: border-box;
  &:hover {

  }
  &:active {
    opacity: 0.9;
    cursor: pointer;
  }
`

export const Button = styled(btn)`
  background-color: ${props => props.disabled ? colors.colors.inactive:colors.colors.main };
  border: 1px solid ${colors.colors.main};
  cursor: ${props => props.disabled ? 'default': 'pointer' };
  color: #fff;
  &:active {
    opacity: ${props => props.disabled? 1 : 0.9};
    cursor: ${props => props.disabled ? 'default': 'pointer' };
  }
`


export const ButtonInline = styled(btn)`
  background-color: ${colors.colors.btnInline};
  // border: 1px solid ${colors.colors.btnInlineBorder};
  border: 1px solid ${colors.colors.btnSecondHover};
  opacity: ${props => props.disabled && '0.5'};
  color: ${colors.colors.font};
  cursor: ${props => props.disabled ? 'default': 'pointer' };
  &:active {
    opacity: ${props => props.disabled ? 0.5 : 0.9};
    cursor: ${props => props.disabled ? 'default': 'pointer' };
  }
`

export const ButtonSecondary = styled(btn)`
  background-color: inherit;
  border: 1px solid ${colors.colors.btnInlineBorder};
  color: ${colors.colors.font};
  opacity: ${props => props.disabled && '0.5'};
  cursor: ${props => props.disabled ? 'default': 'pointer' };
  &:hover{
    background-color: ${props => props.disabled ? 'inherit': colors.colors.btnSecondHover};
    border: 1px solid ${colors.colors.btnSecondHover};
  }
  &:active {
    opacity: ${props => props.disabled? 1 : 0.9};
    cursor: ${props => props.disabled ? 'default': 'pointer'};
  }
`