import React from "react";
import styled from "styled-components";
import {FuncCard} from "./cars";


const cards = (settings) => [
  {key: 1, name: 'empty'},
  {key: 2, name: 'card2',
    content: 'Загрузить выписки вручную из банков',
    line: 'bottom',
    status: 'Process'
  },
  {key: 3, name: 'card3',
    content: 'Интегрировать сервис с банками',
    line: 'bottom',
    status: 'Process'
  },
  // {key: 4, name: 'empty'},
  {key: 5, name: 'card5',
    content: 'Планировать зачисления и выплаты',
    line: 'bottom',
  },
  {key: 6, name: 'card6',
    content: 'Формировать отчеты',
    line: 'left'},
  {key: 7, name: 'card7',
    content: 'Загружать выписки атоматически',
    line: 'left'},
  // {key: 8, name: 'empty'},
  {key: 9, name: 'card9',
    content: 'Настроить модель прогнозирования',
    line: 'bottom'},
  {key: 10, name: 'card10',
    content: 'Контролировать всю группу компаний',
    line: 'top'},
  {key: 11, name: 'card11',
    content: 'Отправлять платежи в банки',
    line: 'top'},
  // {key: 12, name: 'empty'},
  {key: 13, name: 'card13',
    content: 'Привлекать или размещать средства'},
  {key: 14, name: 'empty'},
  {key: 15, name: 'card15',
    content: 'Настроить Cash pooling',
    line: 'top'},
  // {key: 16, name: 'card16',
  //   content: 'Получать отчеты по пулам',
  //   line: 'left'},
]
const Divs = (settings) => cards(settings).map(
    card => {
      if (card.name === 'empty'){
        return <div key={card.key}/>
      } else {
        return <div key={card.key}>

          <FuncCard line={card.line} status={card.status}>
            {card.content}
          </FuncCard>
        </div>
      }
    }
)

export default function Diagram(props){
  return(
      <>
        <Wrapper>
          <div></div>
          <div className={'header-diagram'}>
            <FuncCard status={'Down'} to={'/company'}>
              <LineLeft/>
              <LineRight/>
              <span>Добавить</span>
              <span>компании и счета</span>
            </FuncCard>
          </div>
          {Divs(props.settings)}
        </Wrapper>
      </>
  )
}
const LineLeft = styled.div`
  position: absolute;
  width: 0;
  height: 20px;
  border-left: 1px solid rgba(0,0,0,0.125);
  bottom: -21px;
  left: 36px
`
const LineRight = styled.div`
  position: absolute;
  width: 0;
  height: 20px;
  border-left: 1px solid rgba(0,0,0,0.125);
  bottom: -21px;
  right: 36px
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-template-rows: repeat(6, 41px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: end;
  & .header-diagram{
    display: flex;
    align-items: start;
    justify-content: center;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  
`
