import React from "react";
import styled from "styled-components";

const WrapperContentPage = (props)=>{
  return(
      <Wrapper {...props}>
        {props.children}
      </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  min-width: 800px;
  margin: auto;
`
export default WrapperContentPage