import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Login from "./pages/login";
import Main from "./pages/main";
import './style.css';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route element={<Login/>} path={"/login"}/>
          <Route element={<Main/>} path={"/*"}/>
        </Routes>
      </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals(console.log());
